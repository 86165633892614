.navbar {
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.navbar .wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
}

.navbar .wrapper .items {
  display: flex;
}

.navbar .wrapper .search {
  display: flex;
  align-items: center;
  border: 0.5px solid lightgray;
  padding: 3px;
}

input {
  border: none;
  outline: none;
  background-color: transparent;
}

input::placeholder {
  font-size: 12px;
}

.navbar .wrapper .items .item {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.navbar .wrapper .items,
item .icon {
  font-size: 16px;
}
