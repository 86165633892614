.form {
  display: inline-block;
  text-align: center;
}

.idLabel {
  display: block;
  font-size: 20px;
  font-weight: bold;
}

.idInput {
  width: 80px;
  border-radius: 10px;
  padding: 10px 12px 10px 12px;
  margin-left: 16px;
  border: 0;
  box-shadow: inset 0.5px 1px 5px rgba(0, 0, 0, 0.3);
  font-size: 16px;
  text-align: center;
}
.idInput::placeholder {
  font-size: inherit;
  font-weight: normal;
}

.button {
  margin-top: 12px;
  border: 0;
  border-radius: 10px;
  padding: 10px 16px 10px 16px;
  background: hsl(208, 95%, 70%);

  color: white;
  font-size: 16px;
  cursor: pointer;
}
.button:hover {
  background: hsl(208, 95%, 60%);
}
.button:active {
  background: hsl(208, 95%, 50%);
}
.button:disabled {
  background: hsl(208, 95%, 90%);
  cursor: default;
}

.cameraContainer {
  display: flex;
  gap: 10px;
}

.camera {
  text-align: center;
}

.snap {
  background: transparent;
  border: 1px solid hsl(208, 95%, 85%);
  border-radius: 10px;
  padding: 3% 4.5% 3% 4.5%;
  color: hsl(208, 60%, 50%);
  cursor: pointer;
}
.snap:hover {
  background-color: hsl(208, 95%, 85%);
}

.stream {
  /* position: absolute; */
  width: 100%;
  border-radius: 10px;
  transform: scaleX(-1);
}
