.sidebar {
  flex: 1;
  border-right: 0.5px solid #bbbbbb;
  min-height: 100vh;
  background-color: white;
  margin-right: 10px;
}

hr {
  height: 0;
  border: 0;
  border-top: 0.5px solid #bbbbbb;
}

.sidebar .top {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar .top .logo {
  font-size: 20px;
  font-weight: bold;
  color: #4ca4f3;
}

.sidebar .center {
  padding-left: 10px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebar .center .title {
  font-size: 10px;
  font-weight: bold;
  color: #999;
  margin-top: 15px;
  margin-bottom: 5px;
}

li {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}

li:hover {
  background-color: #dfecff;
}

.sidebar .center .icon {
  font-size: 18px;
  color: #4ca4f3;
}

span {
  font-size: 13px;
  font-weight: 600;
  color: #888;
  margin-left: 10px;
}
