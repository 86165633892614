.pagination {
  height: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.page {
  height: 100%;
  user-select: none;
}

.changePageButton {
  display: flex;
  padding: 0;
  margin-right: 20px;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 32px;
  border: 0;
  border-radius: 10px;
  background: hsl(208, 95%, 70%);

  color: white;
  cursor: pointer;
}
.changePageButton:hover {
  background: hsl(208, 95%, 60%);
}
.changePageButton:disabled {
  background: hsl(208, 95%, 90%);
  cursor: default;
}

.pageInput {
  height: 100%;
  width: 30px;
  margin-left: 20px;
  margin-right: 20px;
  width: 40px;
  border-radius: 10px;
  margin-left: 16px;
  border: 0;
  box-shadow: inset 0.5px 1px 5px rgba(0, 0, 0, 0.3);
  font-size: 16px;
  text-align: center;
}
.pageSearch {
  width: 200px;
  padding: 16px;
  text-align: left;
}
.pageSearch::placeholder {
  font-size: 16px;
}

.tableContainer {
  --tableBorder: 1px solid black;

  border: var(--tableBorder);
  border-radius: 25px;

  overflow: hidden;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.th,
.td {
  border: var(--tableBorder);
  padding: 15px;
}

.th {
  border-top-width: 0;
  cursor: pointer;
  user-select: none;
  padding-right: 18px;
}
.th:after {
  content: "▼";
  position: absolute;
  opacity: 0%;
  font-size: 10px;
  padding-top: 5px;
  padding-left: 4px;
}
.th:is(.ascending, .descending):after {
  opacity: 100%;
}
.th.ascending:after {
  content: "▲";
}
.th:not(.ascending, .descending):hover:after {
  opacity: 50%;
}

.tr:last-of-type .td {
  border-bottom-width: 0;
}

:is(.th, .td):first-of-type {
  border-left-width: 0;
}

:is(.th, .td):last-of-type {
  border-right-width: 0;
}

tbody .tr:hover {
  background: hsl(0, 0%, 90%);
}
